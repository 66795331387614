import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import Loader from 'rsuite/Loader';

import SignIn from '../components/authentication/SignIn';
import { DbError } from '../components/errors/database';
import { destroyDB, initializeDB } from '../rxdb/database/initialize-db';
import { useAuth } from './auth';

const messages = defineMessages({
  loading: {
    id: 'app.database.loading',
    defaultMessage: 'Connecting to {user} database…'
  }
});

/**
 * Database context
 */
const DatabaseContext = React.createContext();

/**
 * Database hook
 *
 * @returns database
 */
export const useDatabase = () => React.useContext(DatabaseContext);

/**
 * Database Provider component
 *
 * Connects to the database and provides access to it
 * for all nested children via db context.
 */
export const DbProvider = ({ children }) => {
  const { formatMessage } = useIntl();

  const { auth: { user } } = useAuth();

  const [database, setDatabase] = React.useState();
  const [error, setError] = React.useState();

  const reset = () => {
    setDatabase();
    setError();
  };

  const initDB = React.useCallback(async username => {
    reset();

    const { err, db } = await initializeDB(`data-${username}`);
    setDatabase(db);
    setError(err);
  }, []);

  const destroy = React.useCallback(
    async username => {
      reset();
      await destroyDB(`data-${username}`);
      await initDB();
    },
    [initDB]
  );

  React.useEffect(() => {
    if (!user?.username) {
      return reset();
    }

    initDB(user.username);

    return reset;
  }, [user, initDB]);

  if (!user?.username) {
    return <SignIn />;
  }

  if (error) {
    return (
      <div data-testid='app-db-error'>
        <DbError destroy={() => destroy(user.username)} />
      </div>
    );
  }

  if (database) {
    return (
      <DatabaseContext.Provider value={database}>
        {children}

        <span data-testid='app-db-loaded' />
      </DatabaseContext.Provider>
    );
  }

  return (
    <span data-testid='app-db-loading'>
      <Loader
        center
        vertical
        speed='slow'
        size='lg'
        content={formatMessage(messages.loading, { user: user.username })}
      />
    </span>
  );
};
