import React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from 'rsuite/Button';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  height: 100vh;

  .text-primary {
    color: var(--rs-primary-500);
  }
`;

/**
 * Database Provider component
 *
 * Connects to the database and provides access to it
 * for all nested children via db context.
 */
export const DbError = ({ destroy }) => {
  return (
    <Container>
      <h1>
        <span className='fa-stack'>
          <i className='fa-solid fa-database fa-stack-1x' />
          <i className='fa-solid fa-ban fa-stack-2x text-primary' />
        </span>
      </h1>

      <h1>
        <FormattedMessage id='app.database.error.title' defaultMessage='Database error' />
      </h1>

      <div>
        <p>
          <FormattedMessage
            id='app.database.error.message'
            defaultMessage='Internal database could not be accessed.'
          />
        </p>
      </div>

      <div>
        <Button
          appearance='primary'
          onClick={destroy}
          size='lg'
          startIcon={<i className='fa-solid fa-arrows-rotate' />}
        >
          <FormattedMessage id='app.database.error.retry' defaultMessage='Recreate database' />
        </Button>
      </div>
    </Container>
  );
};
