import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import Loader from 'rsuite/Loader';

import { DbError } from '../components/errors/database';
import { destroyDB, initializeDB } from '../rxdb/database/initialize-db';

const messages = defineMessages({
  loading: {
    id: 'app.auth.database.loading',
    defaultMessage: 'Connecting to auth database…'
  }
});

/**
 * Database context
 */
const AuthDatabaseContext = React.createContext();

/**
 * Database hook
 *
 * @returns database
 */
export const useAuthDatabase = () => React.useContext(AuthDatabaseContext);

/**
 * Authentication Database Provider component
 *
 * Connects to the database and provides access to it
 * for all nested children via db context.
 */
export const AuthDbProvider = ({ children }) => {
  const { formatMessage } = useIntl();

  const [database, setDatabase] = React.useState();
  const [error, setError] = React.useState();

  const reset = () => {
    setDatabase();
    setError();
  };

  const initDB = React.useCallback(async () => {
    reset();

    const { err, db } = await initializeDB('auth');
    setDatabase(db);
    setError(err);
  }, []);

  const destroy = React.useCallback(async () => {
    reset();
    await destroyDB('auth');
    await initDB();
  }, [initDB]);

  React.useEffect(() => {
    initDB();

    return reset;
  }, [initDB]);

  if (error) {
    return (
      <div data-testid='app-auth-db-error'>
        <DbError destroy={destroy} />
      </div>
    );
  }

  if (database) {
    return (
      <AuthDatabaseContext.Provider value={database}>
        {children}

        <span data-testid='app-auth-db-loaded' />
      </AuthDatabaseContext.Provider>
    );
  }

  return (
    <span data-testid='app-auth-db-loading'>
      <Loader center vertical speed='slow' size='lg' content={formatMessage(messages.loading)} />
    </span>
  );
};
