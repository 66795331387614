import React from 'react';

import { startSynchronization, stopSynchronization } from '../rxdb/replications';
import { useAuth } from './auth';
import { useDatabase } from './database';

const JwtErrors = [
  'Error decoding signature',
  'Signature has expired',
  'User is disabled'
];

/**
 * Replication Provider component
 *
 * Handles database replication.
 */
export const ReplicationProvider = ({ children }) => {
  const database = useDatabase();
  const { auth, logout } = useAuth();

  const [replications, setReplications] = React.useState([]);

  /**
   * Start synchronization with a new JWT value
   */
  React.useEffect(() => {
    if (!auth.token) return;

    // start synchronization when there is login data
    const repl = startSynchronization(database, auth);
    setReplications(repl);

    return () => {
      stopSynchronization(repl);
      setReplications([]);
    };
  }, [database, auth]);

  /**
   * Stop synchronization if no token
   */
  React.useEffect(() => {
    // stop synchronization when there is no login data
    if (!auth.token && replications.length > 0) {
      stopSynchronization(replications);
      setReplications([]);
    }
  }, [auth.token, replications]);

  /**
   * Check replication errors
   */
  React.useEffect(() => {
    replications.forEach((replication) => {
      replication.error$.subscribe((err) => {
        const errMessage = err.toString();
        JwtErrors.forEach((message) => {
          if (errMessage.includes(message)) {
            logout();
          }
        });
      });
    });
  }, [replications, logout]);

  return children;
};
