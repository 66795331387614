import ReactECharts from 'echarts-for-react';
import { groupBy } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';

import { StyledChartsPanel } from '../../../containers/styled/styled';
import { useDatabase } from '../../../context/database';
import { getCollection } from '../../../rxdb/collections';
import { StyledSubHeading } from '../styled';

const headingStyle = {
  color: '#010005',
  marginTop: 0
};
const chartColors = ['#B02C17', '#BDBDBD', '#D97706', '#554F4E'];

const TestsType = ({ where, title, onChange, onChartImageReady }) => {
  const { formatMessage } = useIntl();
  const db = useDatabase();

  const chartRef = React.useRef(null);

  const [options, setOptions] = React.useState({});

  const handleChartFinished = () => {
    const chart = chartRef.current.getEchartsInstance();
    const chartImage = chart.getDataURL({ pixelRatio: 2 });
    onChartImageReady(chartImage);
  };

  React.useEffect(() => {
    const subscription = getCollection(db, 'test')
      .find()
      .where(where)
      .$
      .subscribe((entries) => {
        const documents = entries.map((data) => {
          return {
            ...data,
            monthYear: new Date(data.createdAt).toLocaleDateString('en-US', { month: '2-digit', year: '2-digit' })
          };
        });

        const groupedData = groupBy(documents, 'monthYear');
        const formattedData = Object.keys(groupedData).map((item) => {
          return {
            data: groupBy(groupedData[item], '_data.testType.name')
          };
        });

        onChange({
          keys: Object.keys(groupedData),
          values: formattedData
        });

        const legends = Object.keys(formattedData[0]?.data || {});
        const series = legends.map((item, index) => {
          return {
            name: item,
            data: [Object.values(formattedData[0].data[item]).length],
            type: 'line',
            smooth: true,
            color: chartColors[index],
            emphasis: {
              disabled: true
            }
          };
        });

        const newOptions = {
          legend: {
            data: legends,
            icon: 'circle',
            top: '20',
            fontFamily: 'Manrope'
          },
          tooltip: {
            trigger: 'item'
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: Object.keys(groupedData),
            axisTick: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: '#D9D9D9'
              }
            },
            axisLabel: {
              color: '#4A4A4A',
              fontFamily: 'Manrope'
            }
          },
          yAxis: {
            type: 'value',
            splitLine: {
              show: false
            },
            axisLabel: {
              fontFamily: 'Manrope'
            }
          },
          series
        };
        setOptions(newOptions);
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [db, where, onChange]);

  React.useEffect(() => {
    if (chartRef.current) {
      chartRef.current.getEchartsInstance().resize();
    }
  }, [options]);

  return (
    <StyledChartsPanel>
      <StyledSubHeading style={headingStyle}>{formatMessage(title)}</StyledSubHeading>
      <ReactECharts
        option={options}
        ref={chartRef}
        onEvents={{ finished: handleChartFinished }}
      />
    </StyledChartsPanel>
  );
};

export default TestsType;
