import { jsPDF as JsPDF } from 'jspdf';
import React from 'react';
import { CSVLink } from 'react-csv';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { CheckboxGroup, Col, DateRangePicker, Dropdown, FlexboxGrid, Grid, Row } from 'rsuite';
import Modal from 'rsuite/Modal';

import exportIcon from '../../assets/svg-images/export.svg';
import filterIcon from '../../assets/svg-images/filter-dash.svg';
import { OpenModal } from '../../containers/styled/alerts';
import { PrimaryButton, PrimaryHoloButton } from '../../containers/styled/buttons';
import { StyledCheckbox } from '../../containers/styled/checkbox';
import { MarginDiv } from '../../containers/styled/layout';
import { DateField, StyledDivider, StyledPanel } from '../../containers/styled/styled';
import { H2, H3, H5, Hr } from '../../containers/styled/typography';
import { useDatabase } from '../../context/database';
import { getCollection } from '../../rxdb/collections';
import { getLast30Days, parseFullDate, parseISODate } from '../../utils/dates';
import { DropdownButton, DropdownList } from '../generic/styled';
import BreadCrumb from '../routing/BreadCrumb';
import BloodDonations from './charts/blood-donations';
import DonorsByAge from './charts/donors-by-age';
import DonorsByBloodGroup from './charts/donors-by-blood-group';
import DonorsByGender from './charts/donors-by-gender';
import TestsType from './charts/test-type';
import TestsByStatus from './charts/tests-by-status';
import { StyledSubHeading } from './styled';

const messages = defineMessages({
  subtitle: {
    id: 'dashboard.subtitle',
    defaultMessage: 'Stats from {a} to {b}'
  },
  export: {
    id: 'dashboard.export',
    defaultMessage: 'Export'
  },
  filter: {
    id: 'dashboard.filter',
    defaultMessage: 'Filter'
  },
  datePeriodPlaceholder: {
    id: 'dashboard.filter.datePeriod.placeholder',
    defaultMessage: 'Select Date'
  },
  // Widgets
  donor: {
    id: 'dashboard.donors',
    defaultMessage: 'Total No. of Registered Donors'
  },
  donation: {
    id: 'dashboard.donations',
    defaultMessage: 'Total No. of Donations'
  },
  bloodProduct: {
    id: 'dashboard.bloodProducts',
    defaultMessage: 'Total No. of Blood Products'
  },
  transfusion: {
    id: 'dashboard.transfusions',
    defaultMessage: 'Total No. of Transfusions'
  },
  appointment: {
    id: 'dashboard.appointments',
    defaultMessage: 'Total No. of Appointments'
  },
  // Charts
  donorsByGender: {
    id: 'dashboard.donorsByGender',
    defaultMessage: 'Proportion of Donors by Gender'
  },
  noOfDonations: {
    id: 'dashboard.noOfDonations',
    defaultMessage: 'Number of Blood Donations'
  },
  donorByBloodGroup: {
    id: 'dashboard.donorByBloodGroup',
    defaultMessage: 'Distribution of Donors by Blood Group'
  },
  donorByAge: {
    id: 'dashboard.donorByAge',
    defaultMessage: 'Distribution of Donors by Age'
  },
  testsByStatus: {
    id: 'dashboard.chart.testsByStatus',
    defaultMessage: 'Distribution of Tests by Status'
  },
  testsByType: {
    id: 'dashboard.chart.testsByType',
    defaultMessage: 'Distribution of Tests by Type'
  }
});

const commonStyles = {
  display: 'flex',
  alignItems: 'center'
};

const btnStyle = {
  padding: '0.65rem 1.25rem',
  ...commonStyles
};

const rowStyle = {
  marginLeft: -16,
  marginRight: -16
};

const widgets = [
  'donor',
  'donation',
  'appointment',
  'bloodProduct',
  'transfusion'
];

const charts = [
  'donorsByGender',
  'donorByBloodGroup',
  'donorByAge',
  'noOfDonations',
  'testsByStatus',
  'testsByType'
];

const sections = [...widgets, ...charts];

const getCount = (record) => {
  return Object.keys(record.data).map((name) => (name + ' - ' + record.data[name].length));
};

const Dashboard = () => {
  const { formatMessage } = useIntl();
  const db = useDatabase();

  // widgets
  const [totals, setTotals] = React.useState({});

  // charts
  const [bloodGroupData, setBloodGroupData] = React.useState({ keys: [], values: [] });
  const [donationData, setDonationData] = React.useState({ keys: [], maleData: [], femaleData: [] });
  const [donorsAgeData, setDonorsAgeData] = React.useState({ keys: [], values: [] });
  const [testStatusData, setTestStatusData] = React.useState({ keys: [], values: [] });
  const [testTypeData, setTestTypeData] = React.useState({ keys: [], values: [] });

  const [donorsData, setDonorsData] = React.useState([]);

  const [visibleSections, setVisibleSections] = React.useState(sections);
  const [filterCheckboxes, setFilterCheckboxes] = React.useState(sections);
  const [showFilter, setShowFilter] = React.useState(false);

  const [csvData, setCsvData] = React.useState([]);
  const [datePeriod, setDatePeriod] = React.useState(getLast30Days());

  const where = React.useMemo(() => ({
    isActive: true,
    createdAt: { $gte: parseISODate(datePeriod[0]), $lte: parseISODate(datePeriod[1]) }
  }), [datePeriod]);

  const handleCheckAll = (_val, checked) => setFilterCheckboxes(checked ? sections : []);
  const handleCheck = val => setFilterCheckboxes(val);
  const handleClose = () => setShowFilter(false);

  const applyFilter = () => {
    setVisibleSections(filterCheckboxes);
    handleClose();
  };

  let genderImage;
  let donationImage;
  let bloodGroupImage;
  let donorAgeImage;
  let testStatusImage;
  let testTypeImage;

  const setImage = (img, key) => {
    switch (key) {
      case 'donorsByGender':
        genderImage = img;
        break;
      case 'noOfDonations':
        donationImage = img;
        break;
      case 'donorByBloodGroup':
        bloodGroupImage = img;
        break;
      case 'donorByAge':
        donorAgeImage = img;
        break;
      case 'testsByStatus':
        testStatusImage = img;
        break;
      case 'testsByType':
        testTypeImage = img;
        break;
      default:
        break;
    }
  };

  const pdfExport = () => {
    const doc = new JsPDF();
    const xOffset = doc.internal.pageSize.width / 2;
    let xPos = 10;
    let yPos = 15;

    doc.text(
      formatMessage(messages.subtitle, {
        a: parseFullDate(datePeriod[0]),
        b: parseFullDate(datePeriod[1])
      }),
      xOffset,
      10,
      { align: 'center' }
    );
    doc.setFont('Helvetica', '');
    doc.setFontSize(6);
    doc.text(`${new Date().toDateString()}`, 190, 9, { align: 'center' });

    widgets.forEach((key, index) => {
      const rectWidth = 60;
      const rectHeight = 20;

      doc.roundedRect(xPos, yPos, rectWidth, rectHeight, 1, 1);
      doc.setFont('Helvetica', 'Bold');
      doc.setFontSize(18);
      doc.text(`${totals[key] || 0}`, xPos + 5, yPos + 8);
      doc.setFontSize(9);
      doc.setFont('Helvetica', '');
      doc.text(`${formatMessage(messages[key])}`, xPos + 5, yPos + 16);

      xPos += rectWidth + 5;

      if ((index + 1) % 3 === 0) {
        xPos = 10;
        yPos += rectHeight + 4;
      }
    });

    if (genderImage) {
      doc.roundedRect(10, 63, 60, 52, 1, 1);
      doc.text(formatMessage(messages.donorsByGender), 17, 70);
      doc.addImage(genderImage, 'PNG', 19, 72, 38, 40);
    }

    if (donationImage) {
      doc.roundedRect(75, 63, 125, 52, 1, 1);
      doc.text(formatMessage(messages.noOfDonations), 112, 70);
      doc.addImage(donationImage, 'PNG', 90, 72, 95, 41);
    }

    if (bloodGroupImage) {
      doc.roundedRect(10, 119, 60, 52, 1, 1);
      doc.text(formatMessage(messages.donorByBloodGroup), 14, 125);
      doc.addImage(bloodGroupImage, 'PNG', 19, 128, 50, 41);
    }

    if (donorAgeImage) {
      doc.roundedRect(75, 119, 60, 52, 1, 1);
      doc.text(formatMessage(messages.donorByAge), 83, 125);
      doc.addImage(donorAgeImage, 'PNG', 84, 128, 50, 41);
    }

    if (testStatusImage) {
      doc.roundedRect(140, 119, 60, 52, 1, 1);
      doc.text(formatMessage(messages.testsByStatus), 147, 125);
      doc.addImage(testStatusImage, 'PNG', 146, 128, 45, 41);
    }

    if (testTypeImage) {
      doc.roundedRect(10, 175, 190, 64, 1, 1);
      doc.text(formatMessage(messages.testsByType), 87, 182);
      doc.addImage(testTypeImage, 'PNG', 25, 186, 170, 52);
    }

    doc.save('dashboard.pdf');
  };

  const renderChartComponent = (key) => {
    switch (key) {
      case 'donorsByGender':
        return (
          <Col key={key} md={8} sm={24}>
            <DonorsByGender
              where={where}
              title={messages.donorsByGender}
              onChange={setDonorsData}
              onChartImageReady={(img) => setImage(img, formatMessage(messages.donorsByGender))}
            />
          </Col>
        );

      case 'noOfDonations':
        return (
          <Col key={key} md={16} sm={24}>
            <BloodDonations
              where={where}
              title={messages.noOfDonations}
              onChange={setDonationData}
              onChartImageReady={(img) => setImage(img, formatMessage(messages.noOfDonations))}
            />
          </Col>
        );

      case 'donorByBloodGroup':
        return (
          <Col key={key} md={8} sm={24}>
            <DonorsByBloodGroup
              where={where}
              title={messages.donorByBloodGroup}
              onChange={setBloodGroupData}
              onChartImageReady={(img) => setImage(img, formatMessage(messages.donorByBloodGroup))}
            />
          </Col>
        );

      case 'donorByAge':
        return (
          <Col key={key} md={8} sm={24}>
            <DonorsByAge
              where={where}
              title={messages.donorByAge}
              onChange={setDonorsAgeData}
              onChartImageReady={(img) => setImage(img, formatMessage(messages.donorByAge))}
            />
          </Col>
        );
      case 'testsByStatus':
        return (
          <Col key={key} md={8} sm={24}>
            <TestsByStatus
              where={where}
              title={messages.testsByStatus}
              onChange={setTestStatusData}
              onChartImageReady={(img) => setImage(img, formatMessage(messages.testsByStatus))}
            />
          </Col>
        );
      case 'testsByType':
        return (
          <Col key={key} sm={24}>
            <TestsType
              where={where}
              title={messages.testsByType}
              onChange={setTestTypeData}
              onChartImageReady={(img) => setImage(img, formatMessage(messages.testsByType))}
            />
          </Col>
        );
      default:
        return null;
    }
  };

  const renderDropdown = (props, ref) => {
    return (
      <DropdownButton style={{ backgroundColor: '#B02C17', color: '#FFF' }} {...props} ref={ref}>
        <img src={exportIcon} alt={formatMessage(messages.export)} />
        <span>{formatMessage(messages.export)}</span>
      </DropdownButton>
    );
  };

  React.useEffect(() => {
    const subscriptions = widgets.map(key =>
      getCollection(db, key)
        .find()
        .where(where)
        .$
        .subscribe((docs) => {
          setTotals(state => ({ ...state, [key]: docs.length }));
        })
    );
    return () => {
      subscriptions.forEach(subscription => subscription.unsubscribe());
    };
  }, [db, where]);

  React.useEffect(() => {
    setCsvData([
      ...widgets.map(key => ({
        name: formatMessage(messages[key]),
        count: totals[key] || 0
      })),
      ...donorsData.map((doc) => ({ name: `${doc.name} Donors data`, count: doc.value })),
      ...bloodGroupData.values.map((doc, index) => ({
        name: `Blood group - ${bloodGroupData.keys?.[index]}`,
        count: doc
      })),
      ...donorsAgeData.values.map((doc, index) => ({
        name: `Age - ${donorsAgeData.keys?.[index]}`,
        count: doc
      })),
      ...testStatusData.values.map((doc, index) => ({
        name: `Test status - ${testStatusData.keys?.[index]}`,
        count: `Pass - ${doc.pass}, Fail - ${doc.fail}`
      })),
      ...testTypeData.values.map((doc, index) => ({
        name: `Test type - ${testTypeData.keys?.[index]}`,
        count: getCount(doc)
      })),
      ...donationData.keys.map((doc, index) => ({
        name: `Blood donation - ${doc}`,
        count: `Male ${donationData.maleData?.[index]}, Female ${donationData.femaleData?.[index]}`
      }))
    ]);
  }, [
    formatMessage,
    totals,
    donorsData,
    bloodGroupData,
    donorsAgeData,
    testStatusData,
    testTypeData,
    donationData
  ]);

  return (
    <FlexboxGrid style={{ padding: '5px 0 15px' }}>
      <FlexboxGrid.Item style={{ flex: 1 }}>
        <BreadCrumb crumbs={['dashboard']} />

        <MarginDiv style={{ ...commonStyles, justifyContent: 'space-between', paddingLeft: 5 }}>
          <div>
            <H3 style={{ fontWeight: 700 }}>
              <FormattedMessage
                id='dashboard.title'
                defaultMessage='Dashboard'
              />
            </H3>
            <H5 style={{ marginTop: 24 }}>
              {formatMessage(messages.subtitle, {
                a: parseFullDate(datePeriod[0]),
                b: parseFullDate(datePeriod[1])
              })}
            </H5>
          </div>

          <div style={{ display: 'flex' }}>
            <PrimaryHoloButton onClick={() => setShowFilter(true)} style={{ padding: '0.4rem 1rem' }}>
              <img style={{ marginRight: 6 }} src={filterIcon} alt={formatMessage(messages.filter)} />
              {formatMessage(messages.filter)}
            </PrimaryHoloButton>

            <FlexboxGrid.Item>
              <DropdownList renderToggle={renderDropdown}>
                <CSVLink data-testid='csv' filename='CSV' data={csvData}>
                  <Dropdown.Item>
                    <FormattedMessage
                      id='dashboard.export.csv'
                      defaultMessage='CSV & Excel'
                    />
                  </Dropdown.Item>
                </CSVLink>

                <Hr />
                <Dropdown.Item data-testid='pdf' onClick={pdfExport}>
                  <FormattedMessage
                    id='dashboard.export.pdf'
                    defaultMessage='PDF'
                  />
                </Dropdown.Item>
              </DropdownList>
            </FlexboxGrid.Item>
          </div>
        </MarginDiv>

        <MarginDiv>
          <Grid fluid>
            <Row style={rowStyle} gutter={16}>
              {widgets
                .filter(key => visibleSections.includes(key))
                .map(key => (
                  <Col key={key} md={8} sm={24}>
                    <StyledPanel>
                      <H2 style={{ fontWeight: 700, color: '#010005' }}>
                        {totals[key] || 0}
                      </H2>
                      <StyledSubHeading style={{ marginBottom: 0 }}>
                        {formatMessage(messages[key])}
                      </StyledSubHeading>
                    </StyledPanel>
                  </Col>
                ))}
            </Row>
          </Grid>

          <Grid fluid>
            <Row style={rowStyle} gutter={16}>
              {charts
                .filter(key => visibleSections.includes(key))
                .map((key) => renderChartComponent(key))}
            </Row>
          </Grid>

          {showFilter && (
            <OpenModal
              onClose={handleClose}
              size='md'
              title={formatMessage(messages.filter)}
            >
              <Modal.Body style={{ marginBottom: 30 }}>
                <Grid fluid>
                  <Row>
                    <Col xs={24}>
                      <Col xs={6}>
                        <FormattedMessage
                          id='dashboard.filter.datePeriod.label'
                          defaultMessage='Date period'
                        />
                      </Col>
                      <Col xs={18}>
                        <DateField
                          value={datePeriod}
                          className='select-md'
                          block
                          placeholder={formatMessage(messages.datePeriodPlaceholder)}
                          cleanable={false}
                          onOk={setDatePeriod}
                          shouldDisableDate={DateRangePicker.afterToday()}
                          ranges={[]}
                        />
                      </Col>
                    </Col>
                  </Row>
                  <StyledDivider style={{ marginLeft: 10 }} />

                  <Row>
                    <Col xs={24}>
                      <Col xs={24}>
                        <StyledCheckbox
                          indeterminate={filterCheckboxes.length > 0 && filterCheckboxes.length < sections.length}
                          checked={filterCheckboxes.length === sections.length}
                          onChange={handleCheckAll}
                        >
                          Select all
                        </StyledCheckbox>
                        <StyledDivider style={{ marginLeft: 10 }} />
                      </Col>
                    </Col>

                    <Col xs={24}>
                      <CheckboxGroup
                        inline
                        style={{ flexWrap: 'wrap' }}
                        name='checkboxList' value={filterCheckboxes} onChange={handleCheck}
                      >
                        {sections.map(key => (
                          <Col key={key} xs={12}>
                            <StyledCheckbox value={key}>
                              {formatMessage(messages[key])}
                            </StyledCheckbox>
                          </Col>
                        ))}
                      </CheckboxGroup>
                    </Col>

                    <Col xs={24}>
                      <Col xs={24}>
                        <Col xs={24}>
                          <PrimaryButton
                            style={{ ...btnStyle, width: '40%', justifyContent: 'center', marginTop: 10 }}
                            onClick={applyFilter}
                          >
                            {formatMessage(messages.filter)}
                          </PrimaryButton>
                        </Col>
                      </Col>
                    </Col>
                  </Row>
                </Grid>
              </Modal.Body>
            </OpenModal>
          )}
        </MarginDiv>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
};

export default Dashboard;
