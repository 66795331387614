import ReactECharts from 'echarts-for-react';
import { groupBy } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';

import { StyledChartsPanel } from '../../../containers/styled/styled';
import { useDatabase } from '../../../context/database';
import { getCollection } from '../../../rxdb/collections';
import { StyledSubHeading } from '../styled';

const headingStyle = {
  color: '#010005',
  marginTop: 0
};

const BloodDonations = ({ where, title, onChange, onChartImageReady }) => {
  const { formatMessage } = useIntl();
  const db = useDatabase();

  const chartRef = React.useRef(null);

  const [keys, setKeys] = React.useState([]);
  const [maleData, setMaleData] = React.useState([]);
  const [femaleData, setFemaleData] = React.useState([]);

  const handleChartFinished = () => {
    const chart = chartRef.current.getEchartsInstance();
    const chartImage = chart.getDataURL({ pixelRatio: 2 });
    onChartImageReady(chartImage);
  };

  React.useEffect(() => {
    const subscription = getCollection(db, 'donation')
      .find()
      .where(where)
      .$
      .subscribe((entries) => {
        const documents = entries.map((data) => ({
          ...data,
          monthYear: new Date(data.createdAt).toLocaleDateString('en-US', { month: '2-digit', year: '2-digit' })
        }));

        const groupedData = groupBy(documents, 'monthYear');
        setKeys(Object.keys(groupedData));

        const formattedData = Object.keys(groupedData).map((data) => {
          return {
            key1: groupedData[data],
            male: groupedData[data].filter((item) => item._data.donor.gender === 'M').length,
            female: groupedData[data].filter((item) => item._data.donor.gender === 'F').length
          };
        });

        setMaleData(formattedData.map((item) => item.male));
        setFemaleData(formattedData.map((item) => item.female));
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [db, where]);

  React.useEffect(() => {
    onChange({ keys, maleData, femaleData });
  }, [keys, maleData, femaleData, onChange]);

  const series = [
    {
      data: maleData,
      type: 'bar',
      stack: 'Male',
      name: 'Male',
      itemStyle: {
        borderRadius: [50, 50, 50, 50]
      },
      emphasis: {
        disabled: true
      },
      label: {
        show: true,
        position: 'insideTop',
        fontSize: 12
      }
    },
    {
      data: femaleData,
      type: 'bar',
      stack: 'Female',
      name: 'Female',
      itemStyle: {
        borderRadius: [50, 50, 50, 50]
      },
      emphasis: {
        disabled: true
      },
      label: {
        show: true,
        position: 'insideTop',
        fontSize: 12
      }
    }
  ];

  const options = {
    tooltip: {
      trigger: 'item'
    },
    grid: {
      top: 0,
      bottom: '8%'
    },
    xAxis: {
      type: 'category',
      data: keys,
      axisTick: {
        show: false
      },
      axisLine: {
        lineStyle: {
          color: '#D9D9D9'
        }
      },
      axisLabel: {
        color: '#4A4A4A',
        fontFamily: 'Manrope'
      }
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: false
      },
      axisLabel: {
        fontFamily: 'Manrope'
      }
    },
    series,
    color: ['#B02C17', '#FBE7E3'],
    barWidth: 34
  };

  return (
    <StyledChartsPanel>
      <StyledSubHeading style={headingStyle}>{formatMessage(title)}</StyledSubHeading>
      <ReactECharts
        option={options}
        onEvents={{
          finished: handleChartFinished
        }}
        ref={chartRef}
      />
    </StyledChartsPanel>
  );
};

export default BloodDonations;
