import moment from 'moment';

/**
 * Returns today in ISO string
 * @returns string
 */
export const getToday = () => moment().format('YYYY-MM-DD');

/**
 * Returns now in ISO string
 * @returns string
 */
export const getNow = () => moment().toISOString();

/**
 * Parses ISO date into Date object
 * @param {string} val
 * @returns Date
 */
export const toDate = (val) => val ? moment(val, ['YYYY-MM-DD', moment.ISO_8601]).toDate() : undefined;

/**
 * Parses ISO date time string into formatted long date time based on current locale
 * @param {Date} val
 * @returns string
 */
export const parseFullDateTime = (val) => moment(toDate(val)).format('LLLL');

/**
 * Parses ISO date time string into formatted date time based on current locale
 * @param {Date} val
 * @returns string
 */
export const parseDateTime = (val) => moment(toDate(val)).format('LLL');

/**
 * Parses ISO date time string into formatted long date based on current locale
 * @param {Date} val
 * @returns string
 */
export const parseFullDate = (val) => moment(toDate(val)).format('LL');

/**
 * Parses ISO date time string into formatted date based on current locale
 * @param {Date} val
 * @returns string
 */
export const parseDate = (val) => moment(toDate(val)).format('ll');

/**
 * Parses ISO date time string into formatted date based on current locale
 * @param {Date} val
 * @returns string
 */
export const parseISODate = (val) => moment(toDate(val)).format('YYYY-MM-DD');

/**
 * Parses ISO date time string into formatted long time based on current locale
 * @param {Date} val
 * @returns string
 */
export const parseFullTime = (val) => moment(toDate(val)).format('LTS');

/**
 * Parses ISO date time string into formatted time based on current locale
 * @param {Date} val
 * @returns string
 */
export const parseTime = (val) => moment(toDate(val)).format('LT');

/**
 * Set the due time
 *
 * If the provided value is not overdue then returns it
 * Else in 6 hours
 *
 * @param {Date} val
 * @returns string
 */
export const setDueTime = (val = undefined) => {
  // check that the date is not overdue
  if (val && moment(toDate(val)).isAfter(moment())) {
    return val;
  }

  // In 6 hours
  return moment().add(6, 'hours').minute(0).second(0).millisecond(0).toISOString();
};

export const getCurrentMonth = () => [moment().startOf('month').toDate(), moment().toDate()];
export const getLast30Days = () => [moment().add(-1, 'month').toDate(), moment().toDate()];
