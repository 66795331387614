import { createRxDatabase, removeRxDatabase } from 'rxdb';
import { getRxStorageDexie } from 'rxdb/plugins/storage-dexie';

import { DB_NAME, DB_PASSWORD, GRAPHQL_ENDPOINT } from '../../utils/environment';
import { authCollections, dataCollections, getCollection } from '../collections';
import { getStorage, sanitize } from './utils';

/**
 * Removes the database.
 * @async
 */
export async function destroyDB (mode) {
  const dbName = sanitize(`${DB_NAME}-${mode}-${GRAPHQL_ENDPOINT}`);
  await removeRxDatabase(dbName, getStorage(getRxStorageDexie()));
  console.info(`Database "${mode}" dropped`);
}

/**
 * Initialize RxDB database.
 * @async
 * @returns An array of two elements:
 *  - the first one is the possible error,
 *  - the second one is the database
 *
 * @example
 *  const [database, setDatabase] = React.useState();
 *  const [error, setError] = React.useState();
 *
 *  const initDB = async () => {
 *    setError(null);
 *    setDatabase(null);
 *
 *    const { err, db } = await initializeDB('auth' | 'data-{username}');
 *
 *    setDatabase(db);
 *    setError(err);
 *  };
 */
export const initializeDB = async mode => {
  try {
    const db = await createRxDatabase({
      name: sanitize(`${DB_NAME}-${mode}-${GRAPHQL_ENDPOINT}`),
      password: `${DB_PASSWORD}-${mode}-${GRAPHQL_ENDPOINT}`,
      storage: getStorage(getRxStorageDexie()),
      multiInstance: false,
      ignoreDuplicate: true
    });

    try {
      if (mode === 'auth') {
        await db.addCollections({ ...authCollections });
        console.debug('Added auth collections.');

        await getCollection(db, 'auth').init();
        console.debug('Added auth entry');
      } else {
        await db.addCollections({ ...dataCollections });
        console.debug('Added data collections.');
      }
    } catch (err) {
      console.error('Error while setting up database');
      console.error(err);
      return { err };
    }

    return { db };
  } catch (err) {
    console.error('Error while connecting to database');
    console.error(err);
    return { err };
  }
};
