import ReactECharts from 'echarts-for-react';
import { groupBy } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';

import { StyledChartsPanel } from '../../../containers/styled/styled';
import { useDatabase } from '../../../context/database';
import { getCollection } from '../../../rxdb/collections';
import { StyledSubHeading } from '../styled';

const headingStyle = {
  color: '#010005',
  marginTop: 0
};

const DonorsByAge = ({ where, title, onChange, onChartImageReady }) => {
  const { formatMessage } = useIntl();
  const db = useDatabase();

  const chartRef = React.useRef(null);

  const [keys, setKeys] = React.useState([]);
  const [values, setValues] = React.useState([]);

  const handleChartFinished = () => {
    const chart = chartRef.current.getEchartsInstance();
    const chartImage = chart.getDataURL({ pixelRatio: 2 });
    onChartImageReady(chartImage);
  };

  React.useEffect(() => {
    const subscription = getCollection(db, 'donor')
      .find()
      .where(where)
      .$
      .subscribe((entries) => {
        const docs = entries.map((data) => {
          const birthdate = new Date(data.dateOfBirth);
          const today = new Date();
          const ageInMilliseconds = today - birthdate;
          const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);

          return { ...data, age: Math.floor(ageInYears) };
        });

        const groupedData = groupBy(docs, 'age');
        const filteredData = Object.keys(groupedData)
          .filter(key => key !== 'null')
          .reduce((obj, key) => {
            obj[key] = groupedData[key];
            return obj;
          }, {});

        setKeys(Object.keys(filteredData));
        setValues(Object.values(filteredData).map(value => value.length));
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [db, where]);

  React.useEffect(() => {
    onChange({ keys, values });
  }, [keys, values, onChange]);

  const options = {
    tooltip: {
      trigger: 'item'
    },
    grid: {
      top: '2%',
      bottom: '8%'
    },
    xAxis: {
      type: 'category',
      data: keys,
      axisTick: {
        show: false
      },
      axisLine: {
        lineStyle: {
          color: '#D9D9D9'
        }
      },
      axisLabel: {
        color: '#4A4A4A',
        fontFamily: 'Manrope'
      }
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: false
      },
      axisLabel: {
        fontFamily: 'Manrope'
      }
    },
    series: [
      {
        data: values,
        type: 'bar',
        itemStyle: {
          borderRadius: [50, 50, 50, 50]
        },
        emphasis: {
          disabled: true
        },
        label: {
          show: true,
          position: 'insideTop',
          fontSize: 12,
          fontFamily: 'Manrope'
        }
      }
    ],
    color: ['#FBE7E3'],
    barWidth: 34
  };

  return (
    <StyledChartsPanel>
      <StyledSubHeading style={headingStyle}>{formatMessage(title)}</StyledSubHeading>
      <ReactECharts
        option={options}
        onEvents={{
          finished: handleChartFinished
        }}
        ref={chartRef}
      />
    </StyledChartsPanel>
  );
};
export default DonorsByAge;
