import { IntlProvider } from 'react-intl';
import styled, { ThemeProvider } from 'styled-components';

import { AuthProvider } from '../context/auth';
import { AuthDbProvider } from '../context/auth-database';
import { DbProvider } from '../context/database';
import { ReplicationProvider } from '../context/database-replication';
import { Theme } from './styled/theme';

/**
 * Include App component basic styles:
 * - Font family
 * - Font size
 * - Font color
 *
 * @private
 */
const Container = styled.div`
  font-family: ${props => props.theme.font.family};
  font-size: ${props => props.theme.font.size};
  color: ${props => props.theme.colors.font};
  background-color: ${props => props.theme.colors.bg};
  margin: 0;
`;

/**
 * BasicProvider component.
 *
 * Wraps the children with:
 * - the IntlProvider component to enable i18n and L11n
 * - the ThemeProvider component to enable styled components theme
 */
export const BasicProvider = ({ children }) => (
  <IntlProvider defaultLocale='en' locale={navigator.locale || 'en'}>
    <ThemeProvider theme={Theme}>
      <Container>
        {/* prettier-ignore */}
        {children}
      </Container>
    </ThemeProvider>
  </IntlProvider>
);

/**
 * AppProvider component.
 *
 * Wraps the children with:
 * - the BasicProvider
 * - the AuthDbProvider component to handle authentication database connection
 * - the AuthProvider component to handle authentication and authorization
 * - the DbProvider component to handle database connection for each logged in user
 * - the ReplicationProvider component to handle database replication
 *
 * @component
 * @example
 * const root = createRoot(document.getElementById('root'));
 *
 * root.render(
 *   <React.StrictMode>
 *     <AppProvider>
 *       <App />
 *     </AppProvider>
 *   </React.StrictMode>
 * );
 */
export const AppProvider = ({ children }) => (
  <BasicProvider>
    <AuthDbProvider>
      <AuthProvider>
        <DbProvider>
          <ReplicationProvider>
            {/* prettier-ignore */}
            {children}
          </ReplicationProvider>
        </DbProvider>
      </AuthProvider>
    </AuthDbProvider>
  </BasicProvider>
);
