import '@fortawesome/fontawesome-free/css/fontawesome.min.css';
import '@fortawesome/fontawesome-free/css/regular.min.css';
import '@fortawesome/fontawesome-free/css/solid.min.css';
import '@fontsource/manrope';
import 'rsuite/dist/rsuite.min.css';
import './index.css';

import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import { AppProvider } from './containers/AppProvider';
import reportWebVitals from './reportWebVitals';
import { expose } from './utils/environment';

expose();

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AppProvider>
      <App />
    </AppProvider>
  </React.StrictMode>
);

reportWebVitals();
