import { addRxPlugin } from 'rxdb';
import { wrappedKeyEncryptionCryptoJsStorage } from 'rxdb/plugins/encryption-crypto-js';
import { RxDBJsonDumpPlugin } from 'rxdb/plugins/json-dump';
import { wrappedKeyCompressionStorage } from 'rxdb/plugins/key-compression';
import { RxDBMigrationPlugin } from 'rxdb/plugins/migration';
import { RxDBQueryBuilderPlugin } from 'rxdb/plugins/query-builder';
import { RxDBUpdatePlugin } from 'rxdb/plugins/update';

/*
CouchDB database names have restrictions in terms of which characters can be used.

Based on these restrictions, a database name:

- must begin with a lowercase letter from a to z, no diacritics etc.
- Each character in the name must be one of:
  - a lowercase letter from a to z
  - a number from 0-9
  - an underscore, dollar sign, open or closed parenthesis
  - the plus and minus signs
  - a slash
- May be no longer than 238 characters.

Or expressed as a Regular Expression: ^[a-z][a-z0-9_$()+/-]{238}$
*/
export const sanitize = value => value.replace(/\W/g, '-').toLowerCase().substring(0, 238);

const prepareRxDB = () => {
  addRxPlugin(RxDBJsonDumpPlugin);
  addRxPlugin(RxDBMigrationPlugin);
  addRxPlugin(RxDBQueryBuilderPlugin);
  addRxPlugin(RxDBUpdatePlugin);
};

prepareRxDB();

export const getStorage = initialStorage => {
  const compressed = wrappedKeyCompressionStorage({ storage: initialStorage });
  const encrypted = wrappedKeyEncryptionCryptoJsStorage({ storage: compressed });
  return encrypted;
};
